<script>
import { required, email } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";

import RegisterModal from '@/components/modals/register-modal.vue';

import api from '@/helpers/api-rest/api.js';

export default {
  components: { 
      RegisterModal
    },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loading: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        const apiEndpoint = `/api/v1/auth/login`;
        api.post({url:apiEndpoint, data:{
          email: this.email,
          password: this.password
        }})
        .then(response => {
          this.loading = false;
          const token = response.data.data.token;
          const user = response.data.data.user;
          
          this.setUser(user);
          localStorage.setItem('authToken', token);
          // localStorage.setItem('user', JSON.stringify(user));
          this.$router.push({ name: 'home' });
        })
        .catch(error => {
          this.loading = false;
          this.error(error?.response?.data?.errors[0]?.title);
        });
      }
    }
  }
};
</script>

<template>
  <div>
    <RegisterModal />
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div class=" mb-3">
                          <a href="/" class="logo">
                            <img src="@/assets/images/banner-cabecera.jpg" height="20" alt="logo" />
                          </a>
                        </div>
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-iDEAFP.png" height="60" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-5">¡Bienvenido a iDEAFP!</h4>
                      </div>

                      <b-alert
                        :variant="notification.type"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                      >{{notification.message}}</b-alert>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Introduce tu email"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email es requerid.</span>
                              <span v-if="!$v.email.email">Porfavor, introduce un email válido.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Contraseña</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Introduce contraseña"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >Contraseña es requerida.</div>
                          </div>

                          <!-- <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Remember me</label>
                          </div> -->

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              :disabled="loading"
                            ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                             Log In</button>
                          </div>

                          <div class="mt-3 text-center">
                            <p>
                              <a href="#" v-b-modal.ModalRegister>Regístrate</a>
                            </p>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> ¿Olvidaste tu contraseña?
                            </router-link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>